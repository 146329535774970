import clsx from "clsx";
import React from "react";

import { Organization } from "../models/organization";
import { makeStyles } from "../styles/makeStyles";
import { getOrganizationImage } from "../util/img";
import { SquareImage } from "./squareImage";

const useStyles = makeStyles<{ width: number }>()((theme, { width }) => ({
    squareImage: {
        maxWidth: width,
        minWidth: width,
    },
    image: {
        border: `1px solid  ${theme.palette.divider}`,
    },
}));

interface Props {
    className?: string;
    width?: number;
    organization: Organization;
}

export const OrganizationAvatar: React.FC<Props> = React.memo(({ className, organization, width = 60 }) => {
    const { classes } = useStyles({ width });

    return (
        <SquareImage
            className={clsx(className, classes.squareImage)}
            imageContainerClassName={classes.image}
            url={getOrganizationImage(organization)}
            borderRadius="50%"
        />
    );
});

import clsx from "clsx";
import React from "react";

import { makeStyles } from "../styles/makeStyles";
import { AspectRatioImage } from "./aspectRatioImage";

const useStyles = makeStyles<{ width?: number | string }>()((_, { width }) => ({
    width: {
        maxWidth: width,
        minWidth: width,
    },
}));

interface Props {
    className?: string;
    imageContainerClassName?: string;
    imageClassName?: string;
    borderRadius?: number | string;
    width?: number | string;
    objectFit?: "cover" | "contain";
    url: string;
    onClick?: () => void;
}

export const SquareImage: React.FC<Props> = React.memo(
    ({ className, imageContainerClassName, imageClassName, url, borderRadius, objectFit, width, onClick }) => {
        const { classes } = useStyles({ width });

        return (
            <AspectRatioImage
                className={clsx(className, width !== undefined ? classes.width : undefined)}
                imageContainerClassName={imageContainerClassName}
                imageClassName={imageClassName}
                url={url}
                borderRadius={borderRadius}
                objectFit={objectFit}
                ratio={1}
                onClick={onClick}
            />
        );
    },
);

import TextField from "@mui/material/TextField";
import React from "react";

import { makeStyles } from "../../shared/styles/makeStyles";

const useStyles = makeStyles()((theme) => ({
    textField: {
        margin: `0 ${theme.spacing(0.5)}`,
        width: theme.spacing(7),
    },
    input: {
        padding: "16.5px 14px",
    },
}));

interface Props {
    inputRef: React.Ref<any>;
    autoFocus?: boolean;
    isDisabled?: boolean;
    value: string;
    onChange: (number: string) => void;
    onBackSpaceCaptured?: () => void;
}

export const VerificationTextField: React.FC<Props> = React.memo(
    ({ inputRef, autoFocus = false, isDisabled = false, value, onChange, onBackSpaceCaptured }) => {
        const { classes } = useStyles();

        return (
            <TextField
                className={classes.textField}
                inputRef={inputRef}
                type="number"
                inputMode="numeric"
                autoFocus={autoFocus}
                disabled={isDisabled}
                sx={{ input: { textAlign: "center" } }}
                variant="outlined"
                autoComplete="off"
                inputProps={{
                    pattern: "[0-9]*",
                }}
                value={value}
                onKeyDownCapture={
                    onBackSpaceCaptured
                        ? (e) => {
                              if (e.key === "Backspace") {
                                  onBackSpaceCaptured();
                              }
                          }
                        : undefined
                }
                InputProps={{
                    classes: {
                        input: classes.input,
                    },
                }}
                onChange={(e) => onChange(getNewValue(value, e.target.value))}
            />
        );
    },
);

const getNewValue = (currentValue: string, value: string) => {
    if (value === "") {
        return "";
    }

    if (currentValue === "") {
        return extractNumbers(value);
    }

    const valueWithoutCurrent = value.replace(currentValue, "");
    if (valueWithoutCurrent === "") {
        return currentValue;
    }

    return extractNumbers(valueWithoutCurrent);
};

const extractNumbers = (str: string) => str.replace(/\D/g, "");

import * as EmailValidator from "email-validator";

export const isValidEmail = (value: string) => EmailValidator.validate(value);

export const truncateEmailDescription = (description: string) => {
    if (description.length <= 300) {
        return description.trim();
    }

    return `${description.slice(0, 300).trim()}...`;
};

import clsx from "clsx";
import React from "react";

import { makeStyles } from "../styles/makeStyles";

const useStyles = makeStyles<{
    ratio: number;
    borderRadius: number | string | undefined;
    objectFit: "cover" | "contain";
}>()((theme, { ratio, borderRadius, objectFit }) => ({
    imageWrapper: {
        width: "100%",
        borderRadius,
    },
    clickable: {
        cursor: "pointer",
    },
    imageContainer: {
        width: "100%",
        height: 0,
        overflow: "hidden",
        paddingTop: `${100 / ratio}%`,
        position: "relative",
        backgroundColor: theme.palette.secondary.main,
        borderRadius,
        "&.no-bg": {
            backgroundColor: "transparent",
        },
    },
    image: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit,
        borderRadius,
    },
}));

interface Props {
    className?: string;
    imageContainerClassName?: string;
    imageClassName?: string;
    borderRadius?: number | string;
    noBackgroundColor?: boolean;
    objectFit?: "cover" | "contain";
    ratio: number;
    url: string;
    onClick?: () => void;
}

export const AspectRatioImage: React.FC<Props> = React.memo(
    ({
        className,
        imageContainerClassName,
        imageClassName,
        url,
        ratio,
        borderRadius,
        noBackgroundColor = false,
        objectFit = "cover",
        onClick,
    }) => {
        const { classes } = useStyles({ ratio, borderRadius, objectFit });

        return (
            <div className={clsx(className, classes.imageWrapper)}>
                <div
                    className={clsx(
                        classes.imageContainer,
                        imageContainerClassName,
                        noBackgroundColor ? "no-bg" : undefined,
                    )}
                >
                    {url && (
                        <img
                            className={clsx(imageClassName, classes.image, !!onClick ? classes.clickable : undefined)}
                            src={url}
                            onClick={onClick}
                        />
                    )}
                </div>
            </div>
        );
    },
);

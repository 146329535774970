import Router from "next/router";
import React from "react";

import { ClickableText } from "../../shared/components/clickableText";
import { Organization } from "../../shared/models/organization";
import { OrganizationInviteInfo } from "../../shared/models/organizationInviteInfo";
import { User } from "../../shared/models/user";
import { UserSessionInfo } from "../../shared/models/userSessionInfo";
import { AppContext } from "../../shared/state/appContext";
import { BasePageContext } from "../../shared/state/basePageContext";
import { makeStyles } from "../../shared/styles/makeStyles";
import { APICode, APIError, apiGet, apiPost } from "../../shared/util/api";
import { getClientOrganizationId, setClientOrganizationId, setClientSessionToken } from "../../shared/util/cookies";
import { PhoneCountryData } from "../../shared/util/country";
import { getFormattedPhoneNumber } from "../../shared/util/phone";
import { handleErrorToast } from "../../shared/util/toast";
import { createUrl } from "../../shared/util/url";
import { VerificationTextField } from "./verificationTextField";

const useStyles = makeStyles()((theme) => ({
    title: {
        fontSize: "1.5rem",
        textAlign: "center",
        marginBottom: theme.spacing(1),
    },
    enterCodeText: {
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
    textFields: {
        display: "flex",
        marginBottom: theme.spacing(5),
    },
}));

interface Props {
    className?: string;
    phoneNumber: string;
    phoneCountryData: PhoneCountryData;
    destination: string | undefined;
    organizationInviteInfo: OrganizationInviteInfo | undefined;
    exitUrl: string | null;
    setSessionInfo: (sessionInfo: UserSessionInfo) => void;
}

export const VerificationFlow: React.FC<Props> = React.memo(
    ({ className, phoneNumber, phoneCountryData, destination, organizationInviteInfo, exitUrl, setSessionInfo }) => {
        const { resetUser, resetOrganization, resetOrganizations } = React.useContext(AppContext);
        const { errorToast, infoToast } = React.useContext(BasePageContext);
        const { classes } = useStyles();

        const [isVerifying, setIsVerifying] = React.useState(false);
        const [isSendingCode, setIsSendingCode] = React.useState(false);
        const [requestCodeCooldownTime, setRequestCodeCooldownTime] = React.useState<number>();

        const [firstNumber, setFirstNumber] = React.useState("");
        const [secondNumber, setSecondNumber] = React.useState("");
        const [thirdNumber, setThirdNumber] = React.useState("");
        const [fourthNumber, setFourthNumber] = React.useState("");

        const [firstNumberSet, setFirstNumberSet] = React.useState(false);
        const [secondNumberSet, setSecondNumberSet] = React.useState(false);
        const [thirdNumberSet, setThirdNumberSet] = React.useState(false);
        const [fourthNumberSet, setFourthNumberSet] = React.useState(false);

        const firstInputRef = React.createRef<HTMLInputElement>();
        const secondInputRef = React.createRef<HTMLInputElement>();
        const thirdInputRef = React.createRef<HTMLInputElement>();
        const fourthInputRef = React.createRef<HTMLInputElement>();

        React.useEffect(() => {
            if (firstNumberSet) {
                secondInputRef.current?.focus();
                setFirstNumberSet(false);
            }
        }, [firstNumberSet, secondInputRef]);

        React.useEffect(() => {
            if (secondNumberSet) {
                thirdInputRef.current?.focus();
                setSecondNumberSet(false);
            }
        }, [secondNumberSet, thirdInputRef]);

        React.useEffect(() => {
            if (thirdNumberSet) {
                fourthInputRef.current?.focus();
                setThirdNumberSet(false);
            }
        }, [thirdNumberSet, fourthInputRef]);

        const completeVerification = React.useCallback(
            async (code: string) => {
                try {
                    const { session_token, user } = await apiPost<{ session_token: string; user: User }>(
                        "/user/verification/complete",
                        {
                            body: { code, phone: `${phoneCountryData.dialCode}${phoneNumber}` },
                        },
                    );

                    if (user.email !== "" && user.full_name !== "") {
                        let organization: Organization | undefined;
                        let joined = false;
                        if (organizationInviteInfo) {
                            try {
                                await apiPost("/org/member", {
                                    sessionToken: session_token,
                                    body: {
                                        invite_secret: organizationInviteInfo.inviteSecret,
                                    },
                                    shouldAlert: (status) => status !== 409,
                                });

                                organization = organizationInviteInfo.organization;
                                joined = true;
                            } catch (error: any) {
                                if (error?.status !== 409) {
                                    setIsVerifying(false);
                                    handleErrorToast({ error, message: "Failed to join organization", errorToast });
                                    return;
                                }
                            }
                        }

                        const organizations = await apiGet<Organization[]>("/orgs", {
                            sessionToken: session_token,
                        }).catch(() => [] as Organization[]);

                        resetUser(user);
                        setClientSessionToken(session_token);
                        if (organizations.length === 0) {
                            Router.push(createUrl("/onboarding", !exitUrl ? {} : { exit_url: exitUrl }));
                            return;
                        }

                        let organizationToSet: Organization;
                        if (!organization) {
                            const orgIdFromCookie = getClientOrganizationId();
                            if (!orgIdFromCookie) {
                                organizationToSet = organizations[0];
                            } else {
                                const filteredOrgs = organizations.filter((org) => org.id === orgIdFromCookie);
                                if (filteredOrgs.length === 0) {
                                    organizationToSet = organizations[0];
                                } else {
                                    organizationToSet = filteredOrgs[0];
                                }
                            }
                        } else {
                            organizationToSet = organization;
                        }

                        setClientOrganizationId(organizationToSet.id);
                        resetOrganization(organizationToSet);
                        resetOrganizations(organizations);

                        const fallbackDestination =
                            organizationToSet.saved_onboarding_step !== "finished" ? "/onboarding" : "/events";

                        Router.push(
                            destination ??
                                createUrl(fallbackDestination, {
                                    ...(joined &&
                                        organizationInviteInfo && { joined: organizationInviteInfo.inviteSecret }),
                                    ...(exitUrl && { exit_url: exitUrl }),
                                }),
                        );
                    } else {
                        setIsVerifying(false);
                        setSessionInfo({ sessionToken: session_token, user });
                    }
                } catch (error: any) {
                    if (error?.status === 404) {
                        errorToast("Invalid code, try again.");
                    } else {
                        handleErrorToast({ error, message: "Unable to validate code.", errorToast });
                    }
                    setFirstNumber("");
                    setSecondNumber("");
                    setThirdNumber("");
                    setFourthNumber("");
                    setIsVerifying(false);
                }
            },
            [
                phoneCountryData.dialCode,
                phoneNumber,
                organizationInviteInfo,
                destination,
                exitUrl,
                resetUser,
                resetOrganization,
                resetOrganizations,
                errorToast,
                setSessionInfo,
            ],
        );

        React.useEffect(() => {
            (async () => {
                if (fourthNumberSet) {
                    setFourthNumberSet(false);
                    setIsVerifying(true);

                    await completeVerification(`${firstNumber}${secondNumber}${thirdNumber}${fourthNumber}`);
                }
            })();
        }, [completeVerification, firstNumber, secondNumber, thirdNumber, fourthNumber, fourthNumberSet, phoneNumber]);

        const formattedPhoneNumber = getFormattedPhoneNumber(phoneNumber, phoneCountryData);

        return (
            <div className={className}>
                <div className={classes.title}>Confirm your number</div>
                <div className={classes.enterCodeText}>Enter the code we sent over SMS to {formattedPhoneNumber}</div>
                <div className={classes.textFields}>
                    <VerificationTextField
                        inputRef={firstInputRef}
                        value={firstNumber}
                        isDisabled={isVerifying}
                        onChange={(value) => {
                            const numbers = value.split("");
                            if (numbers.length !== 0) {
                                if (numbers.length === 4) {
                                    setFirstNumber(numbers[0]);
                                    setSecondNumber(numbers[1]);
                                    setThirdNumber(numbers[2]);
                                    setFourthNumber(numbers[3]);
                                    setFourthNumberSet(true);
                                } else {
                                    setFirstNumber(numbers[0]);
                                    setFirstNumberSet(true);
                                }
                            } else if (fourthNumber !== "") {
                                setFourthNumber("");
                            } else if (thirdNumber !== "") {
                                setThirdNumber("");
                            } else if (secondNumber !== "") {
                                setSecondNumber("");
                            } else if (firstNumber !== "") {
                                setFirstNumber("");
                            }
                        }}
                        autoFocus
                    />
                    <VerificationTextField
                        inputRef={secondInputRef}
                        value={secondNumber}
                        isDisabled={firstNumber === "" || isVerifying}
                        onBackSpaceCaptured={() => {
                            if (secondNumber === "") {
                                setFirstNumber("");
                                firstInputRef.current?.focus();
                            }
                        }}
                        onChange={(value) => {
                            const numbers = value.split("");
                            if (numbers.length !== 0) {
                                if (numbers.length === 4) {
                                    setFirstNumber(numbers[0]);
                                    setSecondNumber(numbers[1]);
                                    setThirdNumber(numbers[2]);
                                    setFourthNumber(numbers[3]);
                                    setFourthNumberSet(true);
                                } else {
                                    setSecondNumber(numbers[0]);
                                    setSecondNumberSet(true);
                                }
                            } else if (fourthNumber !== "") {
                                setFourthNumber("");
                            } else if (thirdNumber !== "") {
                                setThirdNumber("");
                            } else if (secondNumber !== "") {
                                setSecondNumber("");
                            }
                        }}
                    />
                    <VerificationTextField
                        inputRef={thirdInputRef}
                        value={thirdNumber}
                        isDisabled={secondNumber === "" || isVerifying}
                        onBackSpaceCaptured={() => {
                            if (thirdNumber === "") {
                                setSecondNumber("");
                                secondInputRef.current?.focus();
                            }
                        }}
                        onChange={(value) => {
                            const numbers = value.split("");
                            if (numbers.length !== 0) {
                                if (numbers.length === 4) {
                                    setFirstNumber(numbers[0]);
                                    setSecondNumber(numbers[1]);
                                    setThirdNumber(numbers[2]);
                                    setFourthNumber(numbers[3]);
                                    setFourthNumberSet(true);
                                } else {
                                    setThirdNumber(numbers[0]);
                                    setThirdNumberSet(true);
                                }
                            } else if (fourthNumber !== "") {
                                setFourthNumber("");
                            } else if (thirdNumber !== "") {
                                setThirdNumber("");
                            }
                        }}
                    />
                    <VerificationTextField
                        inputRef={fourthInputRef}
                        value={fourthNumber}
                        isDisabled={thirdNumber === "" || isVerifying}
                        onBackSpaceCaptured={() => {
                            if (fourthNumber === "") {
                                setThirdNumber("");
                                thirdInputRef.current?.focus();
                            }
                        }}
                        onChange={(value) => {
                            const numbers = value.split("");
                            if (numbers.length !== 0) {
                                if (numbers.length === 4) {
                                    setFirstNumber(numbers[0]);
                                    setSecondNumber(numbers[1]);
                                    setThirdNumber(numbers[2]);
                                    setFourthNumber(numbers[3]);
                                } else {
                                    setFourthNumber(numbers[0]);
                                }
                                setFourthNumberSet(true);
                            } else if (fourthNumber !== "") {
                                setFourthNumber("");
                            }
                        }}
                    />
                </div>
                <ClickableText
                    isDisabled={isVerifying || isSendingCode || requestCodeCooldownTime !== undefined}
                    onClick={async () => {
                        if (requestCodeCooldownTime !== undefined) {
                            return;
                        }
                        setIsSendingCode(true);
                        try {
                            await apiPost("/user/verification/send", {
                                body: {
                                    phone: `${phoneCountryData.dialCode}${phoneNumber}`,
                                },
                                shouldAlert: (_status, error) =>
                                    !(error instanceof APIError && error.code === APICode.TooManyVerificationRequests),
                            });
                            infoToast(`A new code was sent to ${formattedPhoneNumber}`);
                            startCodeCooldown(requestCodeCooldownTime, setRequestCodeCooldownTime);
                        } catch (error: any) {
                            if (error instanceof APIError && error.code === APICode.TooManyVerificationRequests) {
                                errorToast(
                                    "You’ve sent too many verification texts.  Please wait 10 minutes then try again.",
                                );
                            } else {
                                handleErrorToast({ error, message: "Failed to send verification text", errorToast });
                            }
                        }
                        setIsSendingCode(false);
                    }}
                >
                    I didn&apos;t get a code
                    {requestCodeCooldownTime !== undefined ? ` (${requestCodeCooldownTime}s)` : ""}
                </ClickableText>
            </div>
        );
    },
);

const startCodeCooldown = (time: number | undefined, setTime: (time: number | undefined) => void) => {
    const currentTime = time === undefined ? 60 : time;
    if (currentTime === 60) {
        setTime(60);
    }

    setTimeout(() => {
        if (currentTime > 1) {
            const newTime = currentTime - 1;
            setTime(newTime);
            startCodeCooldown(newTime, setTime);
        } else if (currentTime === 1) {
            setTime(undefined);
        }
    }, 1_000);
};

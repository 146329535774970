import clsx from "clsx";
import React from "react";

import { makeStyles } from "../styles/makeStyles";
import { stopPropagationAndDefault } from "../util/handlers";

const useStyles = makeStyles<{ color: "primary" | "midGray"; fontSize: string }>()((theme, { color, fontSize }) => ({
    text: {
        color: color === "primary" ? theme.palette.text.primary : theme.palette.midGray,
        fontSize,
    },
    clickable: {
        cursor: "pointer",
    },
    underline: {
        textDecoration: "underline",
    },
    disabledText: {
        pointerEvents: "none",
        opacity: 0.5,
    },
}));

type Props = React.PropsWithChildren<{
    className?: string;
    isDisabled?: boolean;
    noUnderline?: boolean;
    color?: "primary" | "midGray";
    fontSize?: string;
    useSpan?: boolean;
    onClick: () => void;
}>;

export const ClickableText: React.FC<Props> = React.memo(
    ({
        className,
        isDisabled = false,
        noUnderline = false,
        color = "primary",
        fontSize = "1rem",
        useSpan = false,
        children,
        onClick,
    }) => {
        const { classes } = useStyles({ color, fontSize });

        if (useSpan) {
            return (
                <span
                    className={clsx(
                        classes.text,
                        classes.clickable,
                        className,
                        isDisabled ? classes.disabledText : undefined,
                        noUnderline ? undefined : classes.underline,
                    )}
                    onClick={onClick}
                >
                    {children}
                </span>
            );
        }

        return (
            <div
                className={clsx(
                    className,
                    classes.text,
                    classes.clickable,
                    isDisabled ? classes.disabledText : undefined,
                    noUnderline ? undefined : classes.underline,
                )}
                onClick={onClick}
                onMouseDown={stopPropagationAndDefault}
            >
                {children}
            </div>
        );
    },
);

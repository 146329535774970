import Typography from "@mui/material/Typography";
import React from "react";

import { CymbalButton } from "../../shared/components/cymbalButton";
import { OrganizationAvatar } from "../../shared/components/organizationAvatar";
import { PhoneInput } from "../../shared/components/phoneInput";
import { OrganizationInviteInfo } from "../../shared/models/organizationInviteInfo";
import { BasePageContext } from "../../shared/state/basePageContext";
import { makeStyles } from "../../shared/styles/makeStyles";
import { APICode, APIError, apiPost } from "../../shared/util/api";
import { PhoneCountryData } from "../../shared/util/country";
import { handleErrorToast } from "../../shared/util/toast";

const useStyles = makeStyles()((theme) => ({
    organizationImage: {
        marginBottom: theme.spacing(3),
    },
    title: {
        fontSize: "1.5rem",
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
    phoneInputContainer: {
        marginBottom: theme.spacing(2),
    },
    errorText: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    infoText: {
        fontSize: "0.875rem",
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    continueButton: {
        "&.Mui-disabled": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

interface Props {
    className?: string;
    organizationInviteInfo: OrganizationInviteInfo | undefined;
    setPhoneVerificationParams: (phoneNumer: string, phoneCountryData: PhoneCountryData) => void;
}

export const SignUpOrSignInFlow: React.FC<Props> = React.memo(
    ({ className, organizationInviteInfo, setPhoneVerificationParams }) => {
        const { errorToast } = React.useContext(BasePageContext);
        const { classes } = useStyles();

        const [phoneNumber, setPhoneNumber] = React.useState("");
        const [showError, setShowError] = React.useState(false);
        const [phoneCountryData, setPhoneCountryData] = React.useState<PhoneCountryData>();
        const [isSendingVerification, setIsSendingVerification] = React.useState(false);

        const isShowingPhoneValidationError = showError && !phoneNumber;

        const sendPhoneVerification = React.useCallback(
            async (phoneNumber: string, phoneCountryData: PhoneCountryData) => {
                setIsSendingVerification(true);
                try {
                    await apiPost("/user/verification/send", {
                        body: {
                            phone: `${phoneCountryData.dialCode}${phoneNumber}`,
                        },
                        shouldAlert: (status, error) =>
                            status !== 400 &&
                            !(error instanceof APIError && error.code === APICode.TooManyVerificationRequests),
                    });
                    setIsSendingVerification(false);
                    setPhoneVerificationParams(phoneNumber, phoneCountryData);
                } catch (error: any) {
                    if (error instanceof APIError && error.code === APICode.TooManyVerificationRequests) {
                        errorToast("You’ve sent too many verification texts.  Please wait 10 minutes then try again.");
                    } else if (error?.status === 400) {
                        errorToast("Invalid phone number");
                    } else {
                        handleErrorToast({ error, message: "Failed to send verification text", errorToast });
                    }
                    setIsSendingVerification(false);
                }
            },
            [errorToast, setPhoneVerificationParams],
        );

        const onContinue = React.useCallback(() => {
            if (!phoneCountryData || !phoneNumber) {
                setShowError(true);
                return;
            }

            sendPhoneVerification(phoneNumber, phoneCountryData);
        }, [phoneCountryData, phoneNumber, sendPhoneVerification]);

        return (
            <div className={className}>
                {organizationInviteInfo && (
                    <OrganizationAvatar
                        className={classes.organizationImage}
                        organization={organizationInviteInfo.organization}
                        width={80}
                    />
                )}
                <div className={classes.title}>
                    {organizationInviteInfo
                        ? `You are invited to be an admin for ${organizationInviteInfo.organization.name}`
                        : "Log in or sign up"}
                </div>
                <PhoneInput
                    className={classes.phoneInputContainer}
                    isError={isShowingPhoneValidationError}
                    onChange={(phone, phoneCountryData) => {
                        setPhoneNumber(phone);
                        setPhoneCountryData(phoneCountryData);
                    }}
                    onEnterKeyPressed={onContinue}
                    autoFocus
                />
                {isShowingPhoneValidationError && (
                    <Typography variant="body1" className={classes.errorText}>
                        Please enter a phone number.
                    </Typography>
                )}
                <div className={classes.infoText}>
                    We&apos;ll text you to confirm your number. Standard message and data rates apply.
                </div>
                <CymbalButton
                    className={classes.continueButton}
                    isLoading={isSendingVerification}
                    onClick={onContinue}
                    fullWidth
                >
                    Continue
                </CymbalButton>
            </div>
        );
    },
);

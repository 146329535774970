import clsx from "clsx";
import React from "react";

import { OrganizationInviteInfo } from "../../shared/models/organizationInviteInfo";
import { UserSessionInfo } from "../../shared/models/userSessionInfo";
import { makeStyles } from "../../shared/styles/makeStyles";
import { PhoneCountryData } from "../../shared/util/country";
import { CompleteSignUpFlow } from "./completeSignUpFlow";
import { SignUpOrSignInFlow } from "./signUpOrSignInFlow";
import { VerificationFlow } from "./verificationFlow";

const useStyles = makeStyles()((theme) => ({
    loginFlowContainer: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    loginFlowContent: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: theme.spacing(6),
    },
    signUpContent: {
        maxWidth: 336,
    },
    verificationContent: {
        maxWidth: theme.breakpoints.values.md,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

interface PhoneVerificationParameters {
    phoneNumber: string;
    phoneCountryData: PhoneCountryData;
}

interface Props {
    destination: string | undefined;
    organizationInviteInfo: OrganizationInviteInfo | undefined;
    exitUrl: string | null;
}

export const LoginPage: React.FC<Props> = React.memo(({ destination, organizationInviteInfo, exitUrl }) => {
    const { classes } = useStyles();

    const [phoneVerificationParams, setPhoneVerificationParams] = React.useState<PhoneVerificationParameters>();
    const [userSessionInfo, setUserSessionInfo] = React.useState<UserSessionInfo>();

    const isSignUp = !phoneVerificationParams;

    return (
        <div className={classes.loginFlowContainer}>
            {isSignUp ? (
                <SignUpOrSignInFlow
                    className={clsx(classes.loginFlowContent, classes.signUpContent)}
                    organizationInviteInfo={organizationInviteInfo}
                    setPhoneVerificationParams={(phoneNumber, phoneCountryData) =>
                        setPhoneVerificationParams({ phoneNumber, phoneCountryData })
                    }
                />
            ) : !userSessionInfo ? (
                <VerificationFlow
                    className={clsx(classes.loginFlowContent, classes.verificationContent)}
                    destination={destination}
                    phoneNumber={phoneVerificationParams.phoneNumber}
                    phoneCountryData={phoneVerificationParams.phoneCountryData}
                    organizationInviteInfo={organizationInviteInfo}
                    exitUrl={exitUrl}
                    setSessionInfo={setUserSessionInfo}
                />
            ) : (
                <CompleteSignUpFlow
                    className={clsx(classes.loginFlowContent, classes.signUpContent)}
                    userSessionInfo={userSessionInfo}
                    destination={destination}
                    organizationInviteInfo={organizationInviteInfo}
                    exitUrl={exitUrl}
                />
            )}
        </div>
    );
});

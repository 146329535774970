import clsx from "clsx";
import React from "react";

import { ChevronDownIcon } from "@primer/octicons-react";
import { IPLocation } from "../models/ipLocation";
import { AppContext } from "../state/appContext";
import { makeStyles } from "../styles/makeStyles";
import {
    CountryCodeToPhoneCountryDataMap,
    CountryToPhoneCountryDataMap,
    PhoneCountryData,
    PhoneCountryDataList,
} from "../util/country";
import { isDigit } from "../util/number";
import { CymbalInput, CymbalInputType } from "./cymbalInput";

type Format = "split" | "joint";

const useStyles = makeStyles<{ format: Format; inputType: CymbalInputType }>()((theme, { format, inputType }) => ({
    phoneInput: {
        border: inputType === "outlined" ? `1px solid ${theme.palette.midGray}` : undefined,
        borderRadius: theme.spacing(1),
    },
    selectSectionLabel: {
        position: "relative",
        display: "flex",
        padding: 0,
        margin: 0,
    },
    select: {
        margin: 0,
        width: "100%",
        borderRadius: format === "split" ? theme.spacing(1) : 0,
        borderTopRightRadius: format === "joint" && inputType === "filled-highlight" ? theme.spacing(1) : undefined,
        borderTopLeftRadius: format === "joint" && inputType === "filled-highlight" ? theme.spacing(1) : undefined,
        border: "none",
        padding: "34px 12px 12px",
        appearance: "none",
        backgroundColor: inputType === "filled-highlight" ? theme.palette.background.paperHighlight : "transparent",
        color: theme.palette.text.primary,
        fontWeight: 300,
        fontSize: "1rem",
        lineHeight: 1.5,
        borderBottom: inputType === "outlined" ? `1px solid ${theme.palette.midGray}` : undefined,
        "&:focus": {
            outline: "none",
        },
    },
    selectLabel: {
        position: "absolute",
        left: 12,
        top: 12,
        fontFamily: "Lexend",
        fontSize: "0.75rem",
        color: theme.palette.midGray,
        pointerEvents: "none",
    },
    downChevronWrapper: {
        position: "absolute",
        right: 12,
        top: 0,
        display: "flex",
        alignItems: "center",
        height: "100%",
        pointerEvents: "none",
    },
    downChevron: {
        color: theme.palette.midGray,
    },
    cymbalInput: {
        marginTop: format === "joint" ? -1 : theme.spacing(2),
        marginBottom: format === "joint" ? -1 : theme.spacing(2),
    },
    cymbalInputWrapper: {
        borderTop:
            inputType === "filled-highlight" && format === "joint" ? `1px solid ${theme.palette.midGray}` : undefined,
        borderTopLeftRadius: inputType === "filled-highlight" && format === "joint" ? `0px !important` : undefined,
        borderTopRightRadius: inputType === "filled-highlight" && format === "joint" ? `0px !important` : undefined,
        borderBottomRightRadius: inputType === "filled-highlight" && format === "joint" ? theme.spacing(1) : undefined,
        borderBottomLeftRadius: inputType === "filled-highlight" && format === "joint" ? theme.spacing(1) : undefined,
    },
    cymbalInputFieldset: {
        backgroundColor: inputType === "filled-highlight" ? theme.palette.background.paperHighlight : "transparent",
        borderTopLeftRadius: format === "split" ? theme.spacing(1) : 0,
        borderTopRightRadius: format === "split" ? theme.spacing(1) : 0,
        borderBottomRightRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
    },
    cymbalInputFieldsetUnfocused: {
        border: format === "joint" ? "none" : undefined,
    },
}));

interface Props {
    className?: string;
    initialValue?: string;
    isError?: boolean;
    errorText?: string;
    autoFocus?: boolean;
    inputType?: CymbalInputType;
    format?: Format;
    initialCountryData?: PhoneCountryData;
    PhoneEndAdornment?: React.ReactNode;
    onChange: (value: string, phoneCountryData: PhoneCountryData) => void;
    onEnterKeyPressed?: () => void;
}

export const PhoneInput: React.FC<Props> = React.memo(
    ({
        className,
        initialValue,
        isError,
        autoFocus,
        errorText,
        initialCountryData,
        PhoneEndAdornment,
        inputType = "outlined",
        format = "joint",
        onChange,
        onEnterKeyPressed,
    }) => {
        const { classes } = useStyles({ format, inputType });
        const { ipLocation } = React.useContext(AppContext);

        const [phoneValue, setPhoneValue] = React.useState(initialValue?.replace(/[^0-9]/g, "") ?? "");
        const [selectedPhoneCountryData, setSelectedPhoneCountryData] = React.useState(
            initialCountryData ?? getPhoneCountryDataFromIp(ipLocation),
        );
        const [isInputFocused, setIsInputFocused] = React.useState(false);

        const formattedPhoneValue = formatPhoneInputValue(phoneValue, selectedPhoneCountryData.format);

        return (
            <div className={clsx(className, classes.phoneInput)}>
                <label className={classes.selectSectionLabel} htmlFor="country">
                    <div className={classes.selectLabel}>Country/Region</div>
                    <select
                        className={classes.select}
                        id="country"
                        value={selectedPhoneCountryData.country}
                        onChange={(e) => {
                            if (e.target.value in CountryToPhoneCountryDataMap) {
                                const newSelectedPhoneCountryData = CountryToPhoneCountryDataMap[e.target.value];
                                setSelectedPhoneCountryData(newSelectedPhoneCountryData);
                                onChange(phoneValue, newSelectedPhoneCountryData);
                            }
                        }}
                    >
                        {PhoneCountryDataList.map(({ country, dialCode }) => (
                            <option key={country} value={country}>
                                {country} ({dialCode})
                            </option>
                        ))}
                    </select>

                    <div className={classes.downChevronWrapper}>
                        <ChevronDownIcon size={24} className={classes.downChevron} />
                    </div>
                </label>
                <CymbalInput
                    className={classes.cymbalInput}
                    wrapperClassName={classes.cymbalInputWrapper}
                    fieldsetClassName={clsx(
                        classes.cymbalInputFieldset,
                        isInputFocused ? undefined : classes.cymbalInputFieldsetUnfocused,
                    )}
                    inputType={inputType}
                    label="Phone number"
                    placeholder={selectedPhoneCountryData.format}
                    type="tel"
                    value={formattedPhoneValue}
                    isError={isError}
                    errorText={errorText}
                    startText={selectedPhoneCountryData.dialCode}
                    EndAdornment={PhoneEndAdornment}
                    onChange={(e) => {
                        let value = e.target.value;
                        if (
                            e.target.value.length === formattedPhoneValue.length - 1 &&
                            !isDigit(formattedPhoneValue.charAt(formattedPhoneValue.length - 1))
                        ) {
                            let idx = e.target.value.length - 1;
                            while (idx > 0 && !isDigit(e.target.value.charAt(idx))) {
                                idx -= 1;
                            }
                            value = e.target.value.slice(0, idx);
                        }

                        const newPhoneValue = value.replace(/[^0-9]/g, "");
                        setPhoneValue(newPhoneValue);
                        onChange(newPhoneValue, selectedPhoneCountryData);
                    }}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    onKeyDown={(e) => {
                        if (onEnterKeyPressed && e.key === "Enter") {
                            onEnterKeyPressed();
                        }
                    }}
                    autoFocus={autoFocus}
                />
            </div>
        );
    },
);

const getPhoneCountryDataFromIp = (ipLocation: IPLocation | undefined) => {
    if (!ipLocation) {
        return CountryCodeToPhoneCountryDataMap["US"];
    }

    if (ipLocation.country_code in CountryCodeToPhoneCountryDataMap) {
        return CountryCodeToPhoneCountryDataMap[ipLocation.country_code];
    }

    return CountryCodeToPhoneCountryDataMap["US"];
};

export const formatPhoneInputValue = (phone: string, format?: string) => {
    if (!format) {
        return phone;
    }

    if (!phone) {
        return "";
    }

    let formattedPhone = format;
    for (const char of phone.split("")) {
        if (formattedPhone.indexOf("X") === -1) {
            formattedPhone = `${formattedPhone}${char}`;
        } else {
            formattedPhone = formattedPhone.replace("X", char);
        }
    }

    return formattedPhone.split("X")[0];
};
